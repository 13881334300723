<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Calculate the pH during the titration of
        <number-value :value="M2SO4" unit="\text{mL}" />
        of
        <chemical-latex content="0.4000 M H2SO4" />
        with
        <chemical-latex content="0.3000 M KOH" />
        after the following addition of the base,
      </p>

      <p v-for="option in options" :key="option.inputIndex">
        <stemble-latex :content="option.text" />
        <calculation-input v-model="inputs[option.inputIndex]" :disabled="!allowEditing" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A5_Q2',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
        studentAnswerC: null,
      },
      options: [
        {
          text: '$\\ce{(a) 0.00 mL}$',
          inputIndex: 'studentAnswerA',
        },
        {
          text: '$\\ce{(b) 30.00 mL}$',
          inputIndex: 'studentAnswerB',
        },
        {
          text: '$\\ce{(c) 70.00 mL}$',
          inputIndex: 'studentAnswerC',
        },
      ],
    };
  },
  computed: {
    M2SO4() {
      return this.taskState.getValueBySymbol('M2SO4').content;
    },
  },
};
</script>
